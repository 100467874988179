<template>
<div>退款参数编辑</div>
</template>

<script>
export default {
name: "refund-payment-edit"
}
</script>

<style scoped>

</style>